import { Theme, alpha } from '@mui/material';
import { resolveTypographyVariantStyles } from '@aspendental/themes';

import { TemplateWidth } from '@/types';
import { optimizeImageUrl } from '@/utils/images/imageOptimization';
import { BACKGROUND_IMAGE_QUALITY } from '@/constants';

const getBackground = (imgUrl: string, theme: Theme) => {
	const gradient = theme.palette.backgrounds?.ODPHeroGradient ? `${theme.palette.backgrounds?.ODPHeroGradient},` : '';

	return `${gradient} url(${imgUrl}) transparent 50% / cover no-repeat`;
};

export const getContainerStyles = (theme: Theme, imgUrl: string, templateWidth: TemplateWidth) => {
	return {
		maxWidth: '1920px',
		width: templateWidth === 'Inset' ? '90%' : '100%',
		margin: '0 auto',
		minHeight: 'calc(100vh - 200px)',
		alignItems: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',

		[theme.breakpoints.between('xs', 'sm')]: {
			padding: '40px 20px',
			minHeight: 'calc(100vh - 200px)',
			background: `linear-gradient(0deg,
		${alpha(theme.palette.primary.dark, 0.85)} 0%,
		${alpha(theme.palette.primary.dark, 0.85)} 100%),
		url(${optimizeImageUrl(
			imgUrl,
			theme.breakpoints.values.sm,
			BACKGROUND_IMAGE_QUALITY
		)}) transparent 50% / cover no-repeat;`,
		},
		[theme.breakpoints.between('sm', 'md')]: {
			padding: '40px 20px',
			minHeight: 'calc(100vh - 200px)',
			background: `linear-gradient(0deg,
		${alpha(theme.palette.primary.dark, 0.85)} 0%,
		${alpha(theme.palette.primary.dark, 0.85)} 100%),
		url(${optimizeImageUrl(
			imgUrl,
			theme.breakpoints.values.md,
			BACKGROUND_IMAGE_QUALITY
		)}) transparent 50% / cover no-repeat;`,
		},
		[theme.breakpoints.up('md')]: {
			background: getBackground(optimizeImageUrl(imgUrl, undefined, BACKGROUND_IMAGE_QUALITY), theme),
		},
		[theme.breakpoints.between('md', 'lg')]: { padding: '60px 2%' },
		[theme.breakpoints.up('lg')]: { padding: '60px 5%' },
	};
};

export const getPromoTitleStyles = {
	display: 'flex',
	textAlign: { xs: 'center', md: 'flex-start' },
};

export const getGridStyles = (theme: Theme) => ({
	minWidth: '45%',
	maxWidth: '100%',
	justifyContent: 'flex-start',
	alignItems: 'flex-start',
	[theme.breakpoints.up('lg')]: { maxWidth: '600px' },
	[theme.breakpoints.between('md', 'lg')]: { maxWidth: '50%' },
	[theme.breakpoints.down('md')]: {
		justifyContent: 'center',
		alignItems: 'center',
	},
});

export const getTitleTextStyles = (isSmallScreen: boolean) => ({
	display: 'flex',
	textAlign: isSmallScreen ? 'center' : 'flex-start',
});

export const getHeadingStyles = (theme: Theme, darkTextColor: boolean) => ({
	...resolveTypographyVariantStyles(theme, 'heroMedium'),
	color: darkTextColor ? theme.palette.text.secondary : theme.palette.text.light,
	textAlign: 'left',
	[theme.breakpoints.down('md')]: {
		textAlign: 'center',
	},
});
