import { getSessionId } from '@aspendental/shared-utils/utils';

import { FacilityBrand, BffBrandType, Facility } from '@/types/generated';
import { officePathByBrand } from '@/utils';
import { TagThemeType } from '@/types';

import { ILocationCardProps } from './StandardGoogleMap/LocationCard/LocationCard';

export const getFacilityDetailsUrl = (facilityBasePath: string | undefined, facilityURL: string) => {
	// need the whole url to reload the page till ODP pages are migrated
	return facilityBasePath
		? `${globalThis.location?.origin}/${facilityBasePath}/${facilityURL}/`
		: `${globalThis.location?.origin}/${facilityURL}/`;
};

export const getUsersPosition = (): Promise<GeolocationPosition> => {
	return new Promise((resolve, reject) => {
		globalThis.navigator?.geolocation.getCurrentPosition(resolve, reject);
	});
};

export const getScheduleButtonUrl = ({
	schedulingPathFromConfig,
	facilityBrand,
	location,
	locationScheduling,
}: {
	schedulingPathFromConfig: string;
	facilityBrand: FacilityBrand | BffBrandType;
	location: Facility;
	locationScheduling?: ILocationCardProps['locationScheduling'];
}): string => {
	const sessionId = typeof window !== 'undefined' ? getSessionId() : '';

	if (!location.brand && facilityBrand === FacilityBrand.AspenDental) {
		return globalThis.location?.origin + `/${schedulingPathFromConfig}/?fc=` + `${location?.code}`;
	}

	if (location.brand === FacilityBrand.AspenDental) {
		if (location?.scheduling?.schedulingUrl) {
			if (location?.scheduling?.schedulingUrl.includes('fc')) {
				return location?.scheduling?.schedulingUrl;
			}
			return location?.scheduling?.schedulingUrl?.concat('/?fc=', location?.code);
		}
		return `${globalThis.location?.origin}/${schedulingPathFromConfig}/?fc=${location?.code}`;
	}

	const schedulingSystem = locationScheduling ? locationScheduling.schedulingSystem : null;

	if (location.brand === FacilityBrand.WellNow) {
		const schedulingUrl = location?.scheduling?.schedulingUrl
			? `${location.scheduling.schedulingUrl}&sessionId=${sessionId}`
			: '#';
		return schedulingSystem === 'Revspring' ? `/schedule-appointment/?fc=${location.code}` : schedulingUrl;
	} else {
		const baseUrl = location?.scheduling?.schedulingUrl ?? '#';
		return baseUrl.includes('fc') ? baseUrl : `${baseUrl}?fc=${location?.code}`;
	}
};

export const getOfficeDetailsUrl = ({
	facilityBasePath,
	themeId,
	address,
}: {
	facilityBasePath?: string;
	themeId: TagThemeType;
	address: Facility['address'];
}): string => {
	const { stateCode: state, city, address1, zipCode } = address;
	const basePath = facilityBasePath ? `/${facilityBasePath}` : '';
	return `${basePath}/${officePathByBrand(themeId, {
		stateStrOptions: {
			stateAbbreviation: state ?? '',
			state: '',
			city: city ?? '',
		},
		cityAddressOptions: {
			city: city ?? undefined,
			address: address1 ?? undefined,
			zip: zipCode ?? undefined,
		},
	})}/`;
};
export enum RequestStatus {
	NotAsked = 'NOTASKED',
	Loading = 'LOADING',
	Success = 'SUCCESS',
	Failure = 'FAILURE',
}
