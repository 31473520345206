import { format } from 'date-fns';

import { getDayOfWeek } from '@/utils/getDayOfWeek';
import { BffWorkingHours } from '@/graphql/__generated/sdk';
import { convertTo24HourFormat } from '@/utils';


type OriginalBrandType = 'PIC' | "Wellnow"

export const get12HourTime = (time: string) => {
	const [hours, minutes] = time.split(':');
	const date = new Date(2023, 1, 1, Number(hours), Number(minutes));
	return format(date, 'h:mmaaa');
};

export const checkIsFacilityOpenBff = (workingHoursForWeek: BffWorkingHours[]) => {
	if (!workingHoursForWeek || !workingHoursForWeek.length) return null;

	const dayOfWeek = getDayOfWeek();

	const workingHoursForDayArr = workingHoursForWeek.filter((hour) => hour.date === dayOfWeek);

	if (workingHoursForDayArr?.[0]?.open?.from && workingHoursForDayArr?.[0]?.open?.to) {
		const { from, to } = workingHoursForDayArr[0].open;

		const currentTime = new Date().toTimeString().split(' ')[0];

		const isCurrentlyOpen =
			from && to && currentTime
				? currentTime >= convertTo24HourFormat(from) && currentTime <= convertTo24HourFormat(to)
				: null;

		return isCurrentlyOpen;
	}
};

export const getBackgroundImageUrl = (imageUrl: string, secondaryImageUrl?: string, originalBrand?: string) => {

	if (
		secondaryImageUrl 
		&& originalBrand 
		&& originalBrand === 'PIC' as OriginalBrandType
	) {
		return secondaryImageUrl
	}
	return imageUrl
}
